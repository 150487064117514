@font-face {
  font-family: 'Product Sans';
  src: url('Assets/font/Product Sans Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans';
  src: url('Assets/font/Product Sans Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Product Sans';
  src: url('Assets/font/Product Sans Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans';
  src: url('Assets/font/Product Sans Bold Italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

.App {
  /* text-align: center; */
  width: 100vw;
  overflow-x: hidden;
  /* 100% of the viewport width */
  /* height: 100vh; */
  /* 100% of the viewport height */
  font-family: 'Product Sans', sans-serif;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media print {
  .App {
    display: block !important;
    /* Ensure the block display for printing */
    margin: 0 !important;
    padding: 0 !important;
  }


  /* Adjust the width of the main content area when printing */
  .main-content {
    width: 100% !important;
    margin-left: 0 !important;
  }

  /* Add styles for printing here */
  body {
    font-size: 10pt;
  }

  /* Ensure images are not cut off */
  img {
    max-width: 100%;
    height: auto;
  }

  .no-print {
    display: none;
  }

  .print-container {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .container {
    max-width: 100%;
  }


  html,
  body {
    height: auto;
  }

  iframe {
    display: none;
  }

  .print-only {
    /* margin-top: 5 !important; */
    display: block !important;
  }
}

.print-only {
  display: none;
}