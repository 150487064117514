html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
}

#root {
    display: flex;
}

.str-chat__channel-list {
    width: 30%;
}

.str-chat__channel {
    width: 100%;
}

.str-chat__thread {
    width: 45%;
}

.str-chat {
    /* margin-top: 70px; */
    height: 100%;
}

/* CompactChat.css */
.chat-icon {
    position: fixed;
    bottom: 2vw;
    right: 50px;
    cursor: pointer;
    z-index: 1
        /* Add more styling as needed */
}

.compact-chat-window {
    position: fixed;
    bottom: 7vw;
    /* Adjust according to your design */
    right: 2vw;
    /* Adjust according to your design */
    width: 40vw;
    /* Adjust to scale the width with the viewport */
    height: 30vw;
    /* Adjust to scale the height with the viewport */
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    /* Add more styling as needed */
    /* border-radius: 12px */
    z-index: 2;
}

.full-chat-window {
    position: fixed;
    top: 70px;
    width: 100vw;
    height: 93vh;
    display: flex;
    z-index: 3;
}